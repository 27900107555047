.single-order-container {
  margin-top: 90px;
  .body {
    .item-row {
      display: flex;
      flex-direction: column;
      .value {
        margin: 4px 0 20px 0;
      }
    }
  }
  .cart-item {
    height: 180px;
    margin-bottom: 20px;
    width: 340px;
    .image {
      height: 135px;
      width: 135px;
    }
    .details {
      height: 100%;
      margin-left: 10px;
      .name {
        font-size: 14px;
        word-break: break-all;
      }
      .amount {
        font-size: 20px;
      }
      .cart-row {
        width: 120px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    & {
      .cart-item {
        .image {
          height: 95px;
          width: 95px;
        }
      }
    }
  }
}
