.landing-page-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  //   top: 0;
  left: 0;
  margin: 0px !important;
  box-sizing: border-box;
  background-color: white;
  z-index: 99999999;
  .hidden-audio {
    position: fixed;
    z-index: -1;
  }
  .carousel {
    .jumbo-video {
      position: absolute;
      right: 0;
      left: 0;
      object-fit: fill;

      background-color: white;
      width: 100vw;
      height: 100vh;
      min-width: 100%;
      min-height: 100%;
      z-index: 999999999;
    }
    .slide {
      height: 100vh !important;
      .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
      }
    }
  }
  .bottom {
    z-index: 99999999;
    position: fixed;
    bottom: 40px;
    margin-left: 10px;
  }
}
