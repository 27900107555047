.single-product-container {
  margin-top: 90px;
  .carousel-container {
    width: 40%;
    .current-image {
      width: 350px;
      height: 350px;
      margin-bottom: 50px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .carousel-item {
      height: 50px;
      width: 50px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition-duration: 0.12s;
      border: 2px solid transparent;
      padding: 5px;
      &:hover {
        opacity: 0.7;
      }
    }
    .carousel-item-active {
      border-color: rgba($color: #000000, $alpha: 0.2);
    }
  }
  .body {
    max-width: 500px;
    margin-left: 30px;
    .cart-row {
      width: 120px;
    }
  }
  & {
    @media screen and (max-width: 900px) {
      flex-direction: column !important;
      align-items: center !important;
      .carousel-container {
        width: 100%;
        align-items: center;
        .current-image {
          width: 150px;
          height: 150px;
          margin-bottom: 50px;
        }
      }
      .body {
        width: 100%;
        margin-left: none !important;
        max-width: unset;
      }
    }
  }
}
