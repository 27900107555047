.nav-none {
  display: none !important;
}
.nav-container {
  border-bottom: 1px solid var(--mantine-color-gray-5);
  position: fixed;
  top: 0;
  right: 0;
  height: 73px;
  z-index: 9;

  background-color: white;
  width: 100vw !important;
  box-sizing: border-box;
  padding: 0 20px;
  .nav-action {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
  .no-radius {
    border-radius: 5px !important;
  }
  .small-search,
  .small-links {
    display: none;
  }
  .small-search-button {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    .logo-container {
      display: none;
    }
    .logo-container {
      display: none;
    }
    .toggle-small-nav {
      position: fixed;
      right: 5px;
    }
    .small-search {
      display: block;
      width: 250px !important;
      margin-left: -10px;
    }
    .small-search-button {
      display: block;
    }
    .small-links {
      display: flex;
      position: fixed;
      width: 80px;
      right: 55px;
      margin-top: 4px;
    }
  }
  @media screen and (max-width: 450px) {
    .small-search {
      width: 170px !important;
    }
  }
  .logo {
    height: 40px;
  }
}
