.cart-container {
  margin-top: 30px;
  .cart-down {
    width: 100%;
    .cart-item {
      height: 180px;
      margin-bottom: 20px;
      width: 100%;
      .image {
        height: 135px;
        width: 135px;
      }
      .details {
        height: 100%;
        margin-left: 10px;
        .name {
          font-size: 14px;
          word-break: break-all;
        }
        .amount {
          font-size: 20px;
        }
        .cart-row {
          min-width: 180px;
        }
      }
    }
  }
  .cart-top {
    width: 100%;
    .total {
      font-size: 30px;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 900px) {
    & {
      flex-direction: column;
      .cart-down,
      .cart-top {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 900px) {
    & {
      .cart-down {
        .cart-item {
          .image {
            height: 95px;
            width: 95px;
          }
        }
      }
    }
  }
}
