.container-404 {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: clip;
  align-items: center;
  justify-content: center;
  .section {
    height: 500px;
    .image {
      height: 400px;
      object-fit: contain;
    }
  }
}
