.register-container {
  min-height: 100vh;
  margin: 20px 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .register {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 750px;
    width: 450px;
    box-sizing: border-box;
    padding: 20px 30px;
    @media screen and (max-width: 500px) {
      width: 90vw;
    }
  }
}
