.orders-container {
  margin-top: 90px;
  .orders {
    .order {
      height: 230px;
      margin-bottom: 25px;
      .top {
        background-color: var(--mantine-color-gray-2);
        padding: 10px 20px;
      }
      .main {
        padding: 20px 20px 10px 20px;
        .body {
          height: 145px;
        }
      }
      @media screen and (max-width: 480px) {
        height: 260px;
        .main {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
