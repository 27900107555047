@import url("./fonts.scss");
* {
  font-family: "Nunito Sans", sans-serif;
  text-decoration: none;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: end;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.pointer {
  cursor: pointer;
}
.width-100 {
  width: 100%;
}
.display-none {
  display: none;
}
