.search-results-container {
  margin-top: 90px;

  .product-cards {
    overflow: clip;
    margin-top: 40px;
    .product-card {
      width: 280px;
      height: 450px;
      box-sizing: border-box;
      cursor: pointer;
      .image {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px 5px 0px 0px;
      }
      .brand-row {
        margin-top: -7.5px;
        width: 100%;
        background-color: #fae1f0;
        height: 35px;
        .brand,
        .type {
          margin: 0 !important;
          padding: 0 !important;
        }
        .brand {
          color: #ff00a1;
          font-weight: 500;
        }
        .type {
          font-weight: 500;
        }
      }
      .body {
        height: 165px;
        padding: 0 8px;
        box-sizing: border-box;
        .name {
          font-size: 14px;
          padding: 2px 0;
          color: #000;
        }
        .review-row {
          .review-count {
            margin-left: 5px;
            font-size: 13.2px;
            color: #000;
          }
        }
        .price-row {
          .discounted-price {
            font-size: 15px;
            color: var(--mantine-color-gray-7);
          }
          .actual-price {
            font-size: 18px;
            color: #ff00a1;
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      .product-card {
        width: 280px;
      }
    }
    @media screen and (max-width: 650px) {
      .product-card {
        width: 240px;
      }
    }
    @media screen and (max-width: 550px) {
      .product-card {
        width: 400px;
      }
    }
    @media screen and (max-width: 450px) {
      .product-card {
        width: 90vw;
      }
    }
  }
}
