.products-container {
  margin-top: 90px;

  .product-cards {
    overflow: clip;
    margin: 40px 0px;
    padding-bottom: 50px;
    .product-card {
      width: 340px;
      height: 460px;
      transition-duration: 0.32s;
      .image {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px 5px 0px 0px;
      }
      .brand-row {
        margin-top: -7.5px;
        width: 100%;
        background-color: #fae1f0;
        height: 35px;
        .brand,
        .type {
          margin: 0 !important;
          padding: 0 !important;
          color: var(--mantine-color-black);
        }
        .brand {
          color: #ff00a1;
          font-weight: 500;
        }
        .type {
          font-weight: 500;
        }
      }
      .body {
        height: 165px;
        padding: 0 8px;
        box-sizing: border-box;
        .name {
          font-size: 14px;
          padding: 2px 0;
          color: var(--mantine-color-black);
        }
        .review-row {
          .review-count {
            margin-left: 5px;
            font-size: 13.2px;
          }
        }
        .price-row {
          .discounted-price {
            text-decoration: line-through;
            font-size: 15px;
            color: var(--mantine-color-gray-7);
          }
          .actual-price {
            font-size: 18px;
            color: #ff00a1;
            margin-left: 10px;
          }
        }
      }
      &:hover {
        transform: scale(0.98);
      }
    }
    @media screen and (max-width: 800px) {
      .product-card {
        width: 280px;
      }
    }
    @media screen and (max-width: 650px) {
      .product-card {
        width: 240px;
      }
    }
    @media screen and (max-width: 550px) {
      .product-card {
        width: 150px;
        height: fit-content;
        padding-bottom: 40px;
        .image {
          height: 150px;
        }
        .body {
          height: 90px;
        }
      }
    }
    @media screen and (max-width: 400px) {
      .product-card {
        width: 140px;
        height: 280px;
        .image {
          height: 140px;
        }
        .body {
          height: 90px;
        }
      }
    }
  }
}
