.home-container {
  .jumbo-container {
    position: absolute;
    left: 0;
    overflow: clip;
    width: 100%;
    .jumbo-background {
      position: absolute;
      left: 0;
      overflow: clip;
      top: 50px;
      width: 100%;
      height: calc(70vh - 73px);

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.6;
    }
    .jumbo-video {
      // position: absolute;
      // z-index: 9999;
      // height: 100vh;
      // left: 0;
      // width: 100vw;
      position: absolute;
      right: 0;
      left: 0;
      width: 100vw;
      min-width: 100%;
      min-height: 100%;
    }
    .jumbo-background-1 {
      background-image: url("../../Assets/IMG/Braid1.jpg");
    }
    .jumbo-background-2 {
      background-image: url("../../Assets/IMG/Braid2.jpg");
    }
    .jumbo-background-3 {
      background-image: url("../../Assets/IMG/Braid3.jpg");
    }
    .jumbo-background-4 {
      background-image: url("../../Assets/IMG/Braid4.jpg");
    }
    .jumbo-background-5 {
      background-image: url("../../Assets/IMG/BS/studio.jpg");
    }
    .jumbo-backdrop {
      position: absolute;
      left: 0;
      overflow: clip;
      top: 50px;
      width: 100vw;
      height: calc(70vh - 73px);
      background-color: black;
    }
    .jumbo-content {
      position: absolute;
      left: 0;
      overflow: clip;
      top: 50px;
      width: 100%;
      height: calc(70vh - 73px);
      box-sizing: border-box;
      padding-bottom: 100px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      .title {
        font-weight: 600;
        font-size: 3rem;
        color: #fff;
        margin-bottom: 20px;
        @media screen and (max-width: 600px) {
          font-size: 2.1rem;
          line-height: 50px;
          text-align: center;
        }
      }
      .price {
        background-color: #fff;
        height: 50px;
        padding: 0px 20px;
        border-radius: 5px;
        margin-bottom: 20px;
      }
    }
  }
  .categories {
    padding-top: 70vh;

    .category {
      height: 350px;
      width: 300px;
      display: flex !important;
      flex-direction: column !important;
      cursor: pointer;
      transition-duration: 0.12s;
      .image {
        height: 250px;
        width: 250px;

        object-fit: cover;
        border-radius: 50%;
      }
      .name {
        font-weight: 500;
      }
      @media screen and (max-width: 920px) {
        width: 250px;
        height: 300px;
        .image {
          width: 200px;
          height: 200px;
        }
      }
      @media screen and (max-width: 520px) {
        width: 170px;
        height: 220px;
        .image {
          width: 145px;
          height: 145px;
        }
        .name {
          font-size: 15px;
          width: 200px;
          text-align: center;
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .product-cards {
    overflow: clip;
    margin: 40px 0px;
    padding-bottom: 50px;
    .product-card {
      width: 340px;
      height: 460px;
      transition-duration: 0.32s;
      .image {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px 5px 0px 0px;
      }
      .brand-row {
        margin-top: -7.5px;
        width: 100%;
        background-color: #fae1f0;
        height: 35px;
        .brand,
        .type {
          margin: 0 !important;
          padding: 0 !important;
        }
        .brand {
          color: #ff00a1;
          font-weight: 500;
        }
        .type {
          font-weight: 500;
        }
      }
      .body {
        height: 165px;
        padding: 0 8px;
        box-sizing: border-box;
        .name {
          font-size: 14px;
          padding: 2px 0;
        }
        .review-row {
          .review-count {
            margin-left: 5px;
            font-size: 13.2px;
          }
        }
        .price-row {
          .discounted-price {
            text-decoration: line-through;
            font-size: 15px;
            color: var(--mantine-color-gray-7);
          }
          .actual-price {
            font-size: 18px;
            color: #ff00a1;
            margin-left: 10px;
          }
        }
      }
      &:hover {
        transform: scale(0.98);
      }
    }
    @media screen and (max-width: 800px) {
      .product-card {
        width: 280px;
      }
    }
    @media screen and (max-width: 650px) {
      .product-card {
        width: 240px;
      }
    }
    @media screen and (max-width: 550px) {
      .product-card {
        width: 150px;
        height: fit-content;
        padding-bottom: 40px;
        .image {
          height: 150px;
        }
        .body {
          height: 90px;
        }
      }
    }
    @media screen and (max-width: 400px) {
      .product-card {
        width: 140px;
        height: 280px;
        .image {
          height: 140px;
        }
        .body {
          height: 90px;
        }
      }
    }
  }
  .ready-carousel {
    overflow: hidden;
    .product-card {
      width: 340px;
      height: 400px;
      cursor: pointer;
      transition-duration: 0.32s;
      .image {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px 5px 0px 0px;
      }
      .brand-row {
        margin-top: -7.5px;
        width: 100%;
        background-color: #fae1f0;
        height: 35px;
        .brand,
        .type {
          margin: 0 !important;
          padding: 0 !important;
        }
        .brand {
          color: #ff00a1;
          font-weight: 500;
        }
        .type {
          font-weight: 500;
        }
      }
      .body {
        height: 100px;
        padding: 0 8px;
        box-sizing: border-box;
        .name {
          font-size: 14px;
          padding: 2px 0;
        }
        .review-row {
          .review-count {
            margin-left: 5px;
            font-size: 13.2px;
          }
        }
        .price-row {
          .discounted-price {
            text-decoration: line-through;
            font-size: 15px;
            color: var(--mantine-color-gray-7);
          }
          .actual-price {
            font-size: 18px;
            color: #ff00a1;
            margin-left: 10px;
          }
        }
      }
      &:hover {
        transform: scale(0.98);
      }
    }
    @media screen and (max-width: 800px) {
      .product-card {
        width: 280px;
        height: 430px;
        .body {
          height: 130px;
        }
      }
    }
    @media screen and (max-width: 650px) {
      .product-card {
        width: 340px;
      }
    }
    @media screen and (max-width: 550px) {
      .product-card {
        width: 90vw;
      }
    }
    @media screen and (max-width: 450px) {
      .product-card {
        width: 80vw;
      }
    }
  }
}
