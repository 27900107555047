.floating-action-container {
  .button {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #e9e9e9;
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 0.12s;
    &:hover {
      background-color: #e0e0e0;
    }
  }
}
